import APIContext from 'contexts/base/APIContext'

export default class TargetMonthlyFactorsContext extends APIContext{
  static contextName = 'targetMonthlyFactors'

  search = async(item, {...options}={}) => {
    const { data } = await this.performRequest('search')({...item, options})
    this.setState({list: data})
    return data
  }

  clear = async () => {
    this.setState({list: [], totalPages: 0, count: 0})
  }
}
