import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import WarningIcon from '@material-ui/icons/Warning';
import { uuid } from 'utils'

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
  default: {
    minWidth: 0
  }
}));


export default function SimplePopover(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const { items, ...rest} = props
  return (
    <div>
      <Badge badgeContent={items.length} color="primary" onClick={handleClick}>
         <WarningIcon color='inherit' />
      </Badge>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        {items.map(item =>
          <Typography key={uuid()} className={classes.typography}>{item.financialYear} {item.action} {item.status}</Typography>
        )}
      </Popover>
    </div>
  );
}
