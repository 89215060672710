import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import * as Containers from 'containers'
import { Route } from 'containers'
import { Authorization  } from 'utils'

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/overview"                   component={Containers.Dashboard.Overview}/>
      <Route exact path="/profile"                    component={Containers.User.Profile} />
      <Route exact path="/"                           component={() =>  <Redirect to={"/overview" }/>}/>
      <Route exact path="/not_found"                  component={Containers.Status.NotFound} />
      <Route exact path="/unauthorized"               component={Containers.Status.Unauthorized} />
      <Route exact path="/apidocs"                    component={Containers.Docs.ApiDocs} requires={Authorization.isDeveloper} />
      <Route component={Containers.Status.NotFound} />
    </Switch>
  )
}

export default Routes