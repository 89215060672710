import React from 'react'
import { useContext, useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { FinancialYearsContext, StationsContext, MpfAdjustmentFactorsContext, TargetMonthlyFactorsContext, MpfOutstandingActionsContext, SnackbarContext } from 'contexts'
import { LabeledSelect, PageContainer, ControlledForm, LoaderOverlay } from 'components'
import { Authorization } from 'utils'
import { useDependency, useFilter, useFormData, useInstanceSaver } from 'hooks'
import { MenuItem, Paper, Button, Typography, TextField, InputAdornment, Grid } from '@material-ui/core';
import { Table, TableHead, TableBody, TableRow, TableCell, Box, makeStyles } from '@material-ui/core';
import {errorStringsFromError, uuid, userFriendlyDate} from 'utils'

const useStyles = makeStyles(theme => ({
  defaultRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
  },
  input: {
    "&:invalid": {
      border: "red solid 2px !important",
      borderRadius: "4px"
    }
  },
  defaultRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
  },
  resultsRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    height: 33,
  },
  auditLeft: {
    display: 'inlineBlock',
    float: 'left'
  },
  auditRight: {
    display: 'inlineBlock',
    float: 'right'
  },
  auditPackedRight: {
    display: 'inlineBlock',
    float: 'right',
    marginRight: 65
  },
}))

const Overview = () => {

  const financialYears       = useContext(FinancialYearsContext.ReactContext)
  const stations             = useContext(StationsContext.ReactContext)
  const adjustments          = useContext(MpfAdjustmentFactorsContext.ReactContext)
  const targetFactors        = useContext(TargetMonthlyFactorsContext.ReactContext)
  const outstandingActions   = useContext(MpfOutstandingActionsContext.ReactContext)
  const snackbar             = useContext(SnackbarContext.ReactContext)

  const [saving, setSaving] = useState(false)
  const [filter, setFilter] = useFilter('overview')

  const [formData,setFormData] = useFormData()
  const [save, errors] = useInstanceSaver(formData, adjustments)

  const [loadingDropdowns, ,] = useDependency(() => (
    Promise.all([
      stations.actions.index({
        pageSize: 10, sort: 'stationSequence'
      }),
      financialYears.actions.index({
        pageSize: 10
      })
    ])
  ), [])

  const [loadingAdjustments, ,reloadAdjustments] = useDependency(() => {
    if (!filter.financialYear) {
      Promise.all([
        adjustments.actions.clear(),
        targetFactors.actions.clear(),
        filter.station = ''
      ])
      return
    }

    Promise.all([
      adjustments.actions.search({
        financialYear: filter.financialYear.id
      }),
      targetFactors.actions.search({
        financialYear: filter.financialYear.id
      }),
      outstandingActions.actions.search(),
      financialYears.actions.index({
        pageSize: 10
      })
    ])
  }, [filter.financialYear])

  const financialYearsList = financialYears.list
  const stationsList = stations.list
  const adjustmentsList = adjustments?.list
  const targetFactorsList = targetFactors?.list
  const outstandingActionsList = outstandingActions?.list


  useEffect(() => {
    if (financialYearsList.length > 0) {
      setFilter(defaultFilters())
    }
  }, [financialYearsList.length])

  const defaultFilters = () => {
    return currentFinancialYear ? {financialYear: {id: currentFinancialYear.id}, station: 'ALL' } : {}
  }

  useEffect(() => {
    if (adjustmentsList.length > 0) {
      setFormData(populateAdjustmentForm(adjustmentsList))
    }
  }, [adjustmentsList])


  const populateAdjustmentForm = (adjustmentsList) => {
    let formData = {}

    adjustmentsList.forEach(adjustment => {
      let stationCode = adjustment.stationCode.toLowerCase()
      formData[`${stationCode}_mof`] = parseFloat(adjustment.mofAdjustment)
      formData[`${stationCode}_fof`] = parseFloat(adjustment.fofAdjustment)
    })
    return formData
  }

  const handleSaveAndRecalc = async () => {
    let data = {...formData, ...{action_type: 'Recalc', financial_year: filter.financialYear.id }}
    await handleSave(data)
  }

  const handleRefreshSaveAndRecalc = async () => {
    let data = {...formData, ...{action_type: 'Refresh', financial_year: filter.financialYear.id }}
    await handleSave(data)
  }


  const currentFinancialYear = financialYearsList.find(financialYear => financialYear?.targetsEditableFlag == 'Y')
  const selectedFinancialYear = financialYearsList.find(financialYear => filter?.financialYear?.id == financialYear?.id)
  const selectedStation = filter.station

  const readOnly = Authorization.readOnly || selectedFinancialYear?.targetsEditableFlag == 'N' || (outstandingActionsList && outstandingActionsList.length > 0)


  const hasValidAdjustments = () => {
    let result = true
    adjustmentsList.forEach(adjustment => {
      let stationCode = adjustment.stationCode.toLowerCase()
      let mof = formData[`${stationCode}_mof`]
      let fof = formData[`${stationCode}_fof`]
      if  (!(/(^[1-9]?[0-9]?(\.[0-9]{1,2})?$)/.test(mof))) result = false
      if  (!(/(^[1-9]?[0-9]?(\.[0-9]{1,2})?$)/.test(fof))) result = false
    })
    return result
  }

  const hasValidAdjustment = (name) => {
    let result = ''
    if  (!(/(^[1-9]?[0-9]?(\.[0-9]{1,2})?$)/.test(formData[name]))) result = 'false'
    return result
  }

  const emptyArrays = adjustmentsList.length == 0 && targetFactorsList.length == 0
  const hasDisabledActions = (selectedFinancialYear === undefined) || readOnly || !hasValidAdjustments()

  const handleSave = async (data) => {
    await setSaving(true)
    await adjustments.actions.recalculate({adjustmentFactors: data}).catch(error => snackbar.actions.show(errorStringsFromError(error).join(', ')))
    await reloadAdjustments()
    setSaving(false)
  }

  const handleFinancialYearFilterChanged = ({ target: { value } }) => {
    const financialYear = financialYearsList.find(financialYear => financialYear.id == value)
    setFilter({ ...filter, financialYear: {id: financialYear.id},  station: 'ALL'})
  }
  const handleStationFilterChanged = ({ target: { value } }) => {
    setFilter({ ...filter, station: value })
  }

  const transformtoStationSequence = (adjustmentsList, stationsList) => {
    adjustmentsList.map(adjustment => {
      const station = stationsList.find(station => station.id == adjustment.stationCode)
      if(station) {
        adjustment.stationSequence = station.stationSequence
      }
      return adjustment
    })
    adjustmentsList = adjustmentsList.sort((a, b) => (a.stationSequence > b.stationSequence) ? 1 : -1)
    return adjustmentsList
  }

  const classes = useStyles()

  const TargetFactorBody = ({ targetFactorsList }) => {
    const selectedTargets = targetFactorsList.filter(row => row.stationCode == selectedStation)
    return (
      <TableBody>
        {selectedTargets.map(target => (
          <TableRow key={target.id} className={classes.resultsRow}>
            <TableCell>{target.targetMonth}</TableCell>
            <TableCell>{parseFloat(target.mofNet).toFixed(2)} %</TableCell>
            <TableCell>{parseFloat(target.mofAdjustment).toFixed(2)} %</TableCell>
            <TableCell>{parseFloat(target.mofCalc).toFixed(2)} %</TableCell>
            <TableCell>{parseFloat(target.fofNet).toFixed(2)} %</TableCell>
            <TableCell>{parseFloat(target.fofAdjustment).toFixed(2)} %</TableCell>
            <TableCell>{parseFloat(target.fofCalc).toFixed(2)} %</TableCell>
            <TableCell>{parseFloat(target.eaf).toFixed(2)} %</TableCell>
            <TableCell>{parseFloat(target.planEaf).toFixed(2)} %</TableCell>
          </TableRow>
        ))}
      </TableBody>
    )
  }

  return (
    <PageContainer>
      {(loadingDropdowns || loadingAdjustments || saving) && <LoaderOverlay /> }
      <Box display="grid" gridTemplateColumns={"1fr 1fr 1fr"} alignItems="end" gridColumnGap="10px">

        <LabeledSelect fullWidth label="Financial Year"
          member='filter.financialYear'
          onChange={handleFinancialYearFilterChanged}
          value={selectedFinancialYear ? selectedFinancialYear.id : undefined}>
          <MenuItem value={undefined}></MenuItem>
          {financialYearsList.map(year =>
            <MenuItem key={year.id} value={year.id}>{`${year.id} ${year.targetsEditableFlag == 'Y' ? '' : '  (closed)'}`}</MenuItem>
          )}
        </LabeledSelect>

        <LabeledSelect fullWidth label="Station"
          member='filter.station'
          onChange={handleStationFilterChanged}
          value={selectedStation ? selectedStation : undefined}>
          <MenuItem key={'ALL'} value={'ALL'}>ALL</MenuItem>
          {stationsList.map(station =>
            <MenuItem key={station.id} value={station.id}>{station.id}</MenuItem>
          )}
        </LabeledSelect>

        <Box display="flex" justifyContent="end">
          <Button variant='contained' color="secondary"  onClick={handleSaveAndRecalc}        disabled={hasDisabledActions || emptyArrays} >Save & Recalc</Button>
          <Button variant='contained' color="secondary"  onClick={handleRefreshSaveAndRecalc} disabled={hasDisabledActions}> Refresh Outages & Recalc</Button>
        </Box>
      </Box>
      <Box display="grid" gridTemplateColumns={"0.5fr 1fr"} gridColumnGap="10px" style={{ overflowX: 'auto' }}>
        <Paper variant="outlined">
          <Typography align='center'></Typography>
          <ControlledForm data={formData} errors={errors} onChange={setFormData}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>STATION</TableCell>
                  <TableCell>MOF ADJUSTMENT</TableCell>
                  <TableCell>FOF ADJUSTMENT</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transformtoStationSequence(adjustmentsList, stationsList).map((adjustment) => (
                  <TableRow key={adjustment.id}  className={classes.defaultRow} >
                    <TableCell className={'MuiTableCell-head'}>{adjustment.stationCode}</TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        variant='outlined'
                        error={hasValidAdjustment(adjustment.stationCode.toLowerCase()+ "_mof")}
                        inputProps={{
                          className: classes.input,
                          readOnly: readOnly,
                        }}
                        name={adjustment.stationCode.toLowerCase()+ "_mof"}
                        label=''
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        variant='outlined'
                        error={hasValidAdjustment(adjustment.stationCode.toLowerCase()+ "_fof")}
                        inputProps={{
                          className: classes.input,
                          readOnly: readOnly,
                        }}
                        name={adjustment.stationCode.toLowerCase()+ "_fof"}
                        label=''
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ControlledForm>
            {filter?.financialYear &&
              <div style={{marginTop: 19}}>
                <span className={classes.auditLeft}>Last Edited: {adjustmentsList && adjustmentsList[0] && adjustmentsList[0].updatedBy}</span>
                <span className={classes.auditRight}>Last Modified: {adjustmentsList && adjustmentsList[0] && adjustmentsList[0]?.modifiedDatetime && userFriendlyDate(adjustmentsList[0].modifiedDatetime)}</span>
              </div>
            }        </Paper>
        <Paper variant="outlined">
          <Typography align='center'></Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>MONTH</TableCell>
                  <TableCell>MOF NET</TableCell>
                  <TableCell>MOF ADJ</TableCell>
                  <TableCell>MOF CALC</TableCell>
                  <TableCell>FOF NET</TableCell>
                  <TableCell>FOF ADJ</TableCell>
                  <TableCell>FOF CALC</TableCell>
                  <TableCell>EAF</TableCell>
                  <TableCell>PLAN EAF</TableCell>
                </TableRow>
              </TableHead>
              {filter.financialYear && filter.station &&
                <TargetFactorBody targetFactorsList={targetFactorsList} />
              }
            </Table>
            {selectedFinancialYear &&
              <div style={{marginTop: 19}}>
                <span className={classes.auditLeft}>Last Recalc: {selectedFinancialYear?.lastRecalcDatetime && userFriendlyDate(selectedFinancialYear?.lastRecalcDatetime)}</span>
                <span className={classes.auditPackedRight}>Last Coke Refresh: {selectedFinancialYear?.lastRefreshDatetime && userFriendlyDate(selectedFinancialYear?.lastRefreshDatetime)}</span>
              </div>
            }
        </Paper>
      </Box>
    </PageContainer >
  )
}

export default (props) => (
  <TargetMonthlyFactorsContext>
      <MpfAdjustmentFactorsContext>
        <StationsContext>
          <FinancialYearsContext>
            <Overview {...props} />
          </FinancialYearsContext>
        </StationsContext>
      </MpfAdjustmentFactorsContext>
  </TargetMonthlyFactorsContext>
)