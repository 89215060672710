import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MuiSelect from '@material-ui/core/Select'

export class Select extends Component{
  static propTypes = {
    error: PropTypes.string,
    helperText: PropTypes.string,
    classes: PropTypes.object
  }

  static defaultProps = {
    classes: {}
  }

  render = () => {
    const { error, label, helperText, classes, ...rest} = this.props
    return (
      <FormControl fullWidth={this.props.fullWidth} error={error} className={classes.root}>
        <InputLabel>{label}</InputLabel>
        <MuiSelect className={classes.select} {...rest}/>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    )
  }

}

export default Select