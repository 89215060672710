import React, {Fragment, useContext, useRef, useState, useEffect} from 'react'
import { Link, useHistory } from 'react-router-dom'
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography';
import PersonIcon from '@material-ui/icons/Person'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles'
import {  VersionsContext, SnackbarContext, UsersContext } from 'contexts'
import Divider from '@material-ui/core/Divider';

import { useMsal } from "@azure/msal-react";
import {  MicrosoftService } from 'services'


const useStyles = makeStyles({
  title: {
    flex: 1
  },
  drawer: {
    width: 200,
  },
  version: {
    position: 'absolute',
    bottom: 0,
    right: 10,
    fontSize: 11,
    color: "#9a9a9a"
  }
})

const Nav = (props) => {
  const users  = useContext(UsersContext.ReactContext)
  const versions = useContext(VersionsContext.ReactContext)
  const snackbar = useContext(SnackbarContext.ReactContext)

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [useMenuOpen, setUserMenuOpen] = useState(false)
  const userMenuButtonRef = useRef()

  const MPF_UI_APP_ENV_NAME = 'MPF_UI_APP_ENV_NAME'

	const { instance } = useMsal();
  const history = useHistory()

  const handleLogin = (props) => {
      instance.loginPopup(MicrosoftService.login())
      	.then(response => {
          users.actions.current()
          snackbar.actions.show('Signed in succesfully')

          const location = (props.savedLocation && !props.savedLocation.match(/^\/?tokens\//)) ?
            props.savedLocation :
            '/'

          history.push(location)

      	}).catch((error) => {
            console.log(error)
            snackbar.actions.show('Signed in failed')
        })
  }

  useEffect(() => {
    versions.actions.getTag()
  }, [versions.actions])

  const classes = useStyles(props)
  return (
    <Fragment>
      <AppBar position="sticky">
        <Toolbar>
          <Typography className={classes.title} variant="h6" color="inherit">
            <Link to='/'>Meridian MPF {process.env.REACT_APP_ENV_NAME ? process.env.REACT_APP_ENV_NAME : MPF_UI_APP_ENV_NAME} </Link>
          </Typography>
          <IconButton color="inherit" onClick={() => setUserMenuOpen(true)} ref={userMenuButtonRef}>
            <PersonIcon/>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Menu
        id="simple-menu"
        anchorEl={userMenuButtonRef.current}
        open={useMenuOpen}
        onClose={() => setUserMenuOpen(false)}
      >
        <MenuItem onClick={handleLogin}>Login</MenuItem>
      </Menu>
      <Drawer
        anchor="right"
        classes={{paper: classes.drawer}}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onClick={() => setDrawerOpen(false)}
      >
        <Divider />
      </Drawer>
        <div className={classes.version}>
          API {versions.tag} / CLIENT {process.env.REACT_APP_VERSION}
        </div>
    </Fragment>
  )
}

export default (props) => (
  <VersionsContext>
    <Nav {...props} />
  </VersionsContext>
)

