import useQueryString from 'hooks/useQueryString'

const emptyFilter = {}

const useFilter = (namespace) => {
  const [urlState, setUrlState] = useQueryString(namespace, 'filter')

  const filter = urlState.filter || emptyFilter
  const setFilter = (newFilter) => {
    const updatedFilter = { ...filter, ...newFilter }
    const cleanedFilter = {}
    Object.keys(updatedFilter || {}).forEach((key) => {
      if (updatedFilter[key] || updatedFilter[key] === 0 || updatedFilter[key] === false) {
        cleanedFilter[key] = updatedFilter[key]
      }
    })
    setUrlState({ filter: cleanedFilter })
    // if (setPage) {
    //   setPage(1)
    // }
  }

  return [filter, setFilter]
}
export default useFilter
