import APIContext from 'contexts/base/APIContext'

export default class MpfAdjustmentFactorsContext extends APIContext{
  static contextName = 'mpfAdjustmentFactors'

  search = async(item, {...options}={}) => {
    const { data } = await this.performRequest('search')({...item, options})
    this.setState({list: data})
    return data
  }

  recalculate = async(item, {...options}={}) => {
    const { data } = await this.performRequest('recalculate')({...item, options})
    return data
  }

  clear = async () => {
    this.setState({list: [], totalPages: 0, count: 0})
  }
}
