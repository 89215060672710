// DO NOT edit this file, add extentions in index.js.
// Changes to this file will be overwritten when regenerating

import { ProtectedJsonAPI } from './ProtectedJsonAPI'

export class ApiPathError extends Error {
  constructor(action, params) {
    super(`No route for ${action} matches the provided params: ${JSON.stringify(params)}`)
    this.action = action
    this.params = params
  }
}

export const API = ProtectedJsonAPI.extend(
  '',
  {
    routeNotFound: endpoint => endpoint.path(() => {
      return '/'
    }),
    tag: endpoint => endpoint.path(() => {
      return '/tag'
    }),
  }
)

export const FinancialYears = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/financial_years'
    }),
  }
)

export const Stations = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/stations'
    }),
  }
)

export const MpfAdjustmentFactors = ProtectedJsonAPI.extend(
  '',
  {
    search: endpoint => endpoint.method('post').path(({financialYear}) => {
      if (financialYear) {
        return '/mpf_adjustment_factors/search'
      }
      throw new ApiPathError('search', {financialYear})
    }),
    recalculate: endpoint => endpoint.method('post').path(({adjustmentFactors}) => {
      if (adjustmentFactors) {
        return '/mpf_adjustment_factors/recalculate'
      }
      throw new ApiPathError('recalculate', {adjustmentFactors})
    }),
  }
)

export const MpfOutstandingActions = ProtectedJsonAPI.extend(
  '',
  {
    search: endpoint => endpoint.method('post').path(() => {
      return '/mpf_outstanding_actions/search'
    }),
  }
)

export const TargetMonthlyFactors = ProtectedJsonAPI.extend(
  '',
  {
    search: endpoint => endpoint.method('post').path(({financialYear}) => {
      if (financialYear) {
        return '/target_monthly_factors/search'
      }
      throw new ApiPathError('search', {financialYear})
    }),
  }
)

export const Users = ProtectedJsonAPI.extend(
  '',
  {
    current: endpoint => endpoint.method('post').path(() => {
      return '/users/current'
    }),
  }
)

