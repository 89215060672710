import { ContextsByType } from 'contexts/base/BaseContext'

class AuthorizationProxy{

  get store(){
    return ContextsByType.users[0].state
  }

  //MPF does not use a user active record
  //so there is no id
  // get userId(){
  //   return this.user.id
  // }

  get user(){
    const user = this.store.currentUser || {}
    return user
  }

  get readOnly(){
    return !this.user.roles.includes('Write')
  }

  get canRead(){
    return this.user.roles.includes('Read') || this.canWrite
  }

  get canWrite(){
    return this.user.roles.includes('Write')
  }

  get isDeveloper(){
    return this.user.roles.includes('Development')
  }

  // get isAdmin(){
  //   return this.user.roles.includes('admin')
  // }
}

export const Authorization = new AuthorizationProxy()
