import { createMuiTheme } from '@material-ui/core/styles'

export const theme = createMuiTheme({
  palette: {
    primary: { main: `${process.env.REACT_APP_THEME_PRIMARY || '#009000'}` },
    secondary: { main: `${process.env.REACT_APP_THEME_SECONDARY || '#005001'}` },
    type: 'light'
  },
  typography: {
    useNextVariants: true,
    fontFamily: 'Raleway, Avenir, Helvetica, arial',
  },
  overrides: {
    MuiAppBar: {
      root: {
        backgroundImage: `url(${process.env.REACT_APP_THEME_BACKGROUND || '/GreenMpfBackground.png'})`,
      }
    },
    MuiPaper: {
      root: {
        '& .link-small': {
          margin: 5,
          textAlign: 'center',
          display: 'block',
          color: '#666565',
          fontSize: '0.8rem',
          '&:hover': {
            textDecoration: 'underline'
          }
        },
        background:  {main: `${process.env.REACT_APP_THEME_PRIMARY ||'#009000' }` },
      }
    },
    MuiCardContent: {
      root: {
        maxWidth: 1600,
        margin: '0 auto',
        width: '100%',
      }
    },
    MuiFormControl: {
      root: {
        // marginBottom: '10px'
      }
    },
    MuiTableCell: {
      root: {
        padding: '1px',
        fontSize: '0.85rem',
        borderBottom: "0px solid rgba(224, 224, 224, 1)"
      },
      body: {
       // border: "1px solid rgba(224, 224, 224, 1)"
      },
      head: {
        fontWeight: 'bold'
      }
    },
    MuiButton: {
      root: {
        marginBottom: '10px',
        '&+$root': {
          marginLeft: '10px'
        }
      }
    },
    MuiPickersModal: {
      dialogRoot: {
        '& .MuiButtonBase-root': {
          marginBottom: 'unset'
        },
      },
    },
  }
})