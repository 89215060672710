import React, {Fragment, useContext, useRef, useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import AuditIcon from '@material-ui/icons/Security'
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import HelpIcon from '@material-ui/icons/Help';
import PersonIcon from '@material-ui/icons/Person'
import DocumentIcon from '@material-ui/icons/Description'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import BookIcon from '@material-ui/icons/Book';
import FlagIcon from '@material-ui/icons/Flag';
import PeopleIcon from '@material-ui/icons/People';
import SubjectIcon from '@material-ui/icons/Subject';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Authorization  } from 'utils'
import { makeStyles } from '@material-ui/core/styles'
import { VersionsContext } from 'contexts'
import Divider from '@material-ui/core/Divider';
import BookmarkIcon from '@material-ui/icons//Bookmark';
import Tooltip from '@material-ui/core/Tooltip'

import Badge from '@material-ui/core/Badge';
import MailIcon from '@material-ui/icons/Mail';
import { SimplePopover } from 'components'
import { MpfOutstandingActionsContext } from 'contexts'

import { useMsal } from "@azure/msal-react";

const useStyles = makeStyles({
  title: {
    flex: 1
  },
  drawer: {
    width: 250,
  },
  version: {
    position: 'fixed',
    bottom: 0,
    right: 10,
    fontSize: 11,
    color: "#9a9a9a"
  }
})

const Nav = (props) => {
  const versions = useContext(VersionsContext.ReactContext)

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [useMenuOpen, setUserMenuOpen] = useState(false)
  const userMenuButtonRef = useRef()

  const outstandingActions   = useContext(MpfOutstandingActionsContext.ReactContext)
  const outstandingActionsList = outstandingActions?.list

  const MPF_UI_APP_ENV_NAME = 'MPF_UI_APP_ENV_NAME'

  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutPopup({ postLogoutRedirectUri: "/", mainWindowRedirectUri: "/" })
      .catch((error) => console.log(error))
  }

  useEffect(() => {
    versions.actions.getTag()
  }, [versions.actions])

  const classes = useStyles(props)
  return (
    <Fragment>
      <AppBar position="sticky">
        <Toolbar>
          <Typography className={classes.title} variant="h6" color="inherit" nowrap={'true'}>
            <Link to='/'>Meridian MPF {process.env.REACT_APP_ENV_NAME ? process.env.REACT_APP_ENV_NAME : MPF_UI_APP_ENV_NAME} </Link>
          </Typography>


          <SimplePopover items={outstandingActionsList} />

          <IconButton color="inherit" aria-label="Menu" onClick={() => setDrawerOpen(true)}>
            <MenuIcon/>
          </IconButton>
          <IconButton color="inherit" onClick={() => setUserMenuOpen(true)} ref={userMenuButtonRef}>
            <PersonIcon/>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Menu
        id="simple-menu"
        anchorEl={userMenuButtonRef.current}
        open={useMenuOpen}
        onClose={() => setUserMenuOpen(false)}
      >
        <Link to="/profile"><MenuItem>My Profile</MenuItem></Link>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
      <Drawer
        anchor="right"
        classes={{paper: classes.drawer}}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onClick={() => setDrawerOpen(false)}
      >

        { Authorization.canRead &&
          <Link to='/overview'>
            <ListItem button>
              <ListItemIcon>
                <SubjectIcon/>
              </ListItemIcon>
              <ListItemText primary="Overview" />
            </ListItem>
          </Link>
        }

        { Authorization.isDeveloper &&
          <Divider />
        }

        { Authorization.isDeveloper &&
          <Link to='/apidocs'>
            <ListItem button>
              <ListItemIcon>
                <DocumentIcon/>
              </ListItemIcon>
              <ListItemText primary="API Docs" />
            </ListItem>
          </Link>
        }
      </Drawer>
        <div className={classes.version}>
          API {versions.tag} / CLIENT {process.env.REACT_APP_VERSION}
        </div>
    </Fragment>
  )
}

export default (props) => (
    <VersionsContext>
      <Nav {...props} />
    </VersionsContext>
)
